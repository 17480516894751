/*
 * @Author: soofly wy463554101@163.com
 * @Date: 2022-07-17 21:12:53
 * @LastEditors: biyuhuan
 * @LastEditTime: 2022-08-02 18:22:34
 * @FilePath: /help.ringle.com/src/main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI, { Message } from 'element-ui'
import storage from '@/util/storage'
import Vconsole from 'vconsole'
import '@/assets/css/normalize.css'
import '@/assets/scss/index.scss'
import 'element-ui/lib/theme-chalk/index.css'
import Vant from 'vant';
import 'vant/lib/index.css';
import 'amfe-flexible'

Vue.use(ElementUI)
Vue.use(Vant);
// vconsole
if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
  var vConsole = new Vconsole()
}

Vue.directive('loadmore', {
    bind(el, binding) {
      const selectWrap = el.querySelector('body-wrapper') || el;
      console.log(selectWrap);
      selectWrap.addEventListener('scroll', function () {
        if (this.scrollHeight - this.scrollTop <= this.clientHeight) {
          binding.value();
          console.log(this.scrollHeight , this.scrollTop , this.clientHeight);
        }
      })
    }
  })

Vue.$message = Vue.prototype.$message = Message
Vue.$storage = Vue.prototype.$storage = storage

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
