<!--
 * @Author: soofly wy463554101@163.com
 * @Date: 2022-07-17 21:12:53
 * @LastEditors: soofly wy463554101@163.com
 * @LastEditTime: 2022-08-01 11:00:57
 * @FilePath: /help.ringle.com/src/App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
export default {
  components: {},
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
html,
body,
#app {
  width: 100%;
  height: 100%;
  background: #f3f6fa;
}
ul,
li {
  list-style-type: none;
}
.el-image-viewer__wrapper {
  z-index: 3001 !important;
}
</style>
