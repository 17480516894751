/*
 * @Author: soofly wy463554101@163.com
 * @Date: 2022-07-17 21:12:53
 * @LastEditors: tzk
 * @LastEditTime: 2023-02-15 14:35:38
 * @FilePath: /help.ringle.com/src/router/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      title: 'Jaguar Job',
    },
    component: () => import('../views/home/index.vue')
  },
  {
    path: '/search',
    name: 'search',
    meta: {
      title: '灵鸽帮助中心-搜索',
    },
    component: () => import('../views/search/index.vue')
  },
  {
    path: '/mhome',
    name: 'mobile',
    meta: {
      title: '',
      keepAlive: true,  // 需要缓存
    },
    component: () => import('../views/mobile/mindex.vue'),
    
  },
  {
    path: '/msearch',
    name: 'msearch',
    meta: {
      title: '搜索',
      keepAlive: true,  // 需要缓存
      isFress:false
    },
    component: () => import('../views/mobile/msearch.vue'),
  },
  {
    path: '/mdetail',
    name: 'mdetail',
    meta: {
      title: '',
    },
    component: () => import('../views/mobile/mdetail.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

// 导航守卫
router.beforeEach(async (to, from, next) => {
  const title = to.meta.title
  if (title) {
    document.title = to.meta.title
  } else {
    // document.title = '灵鸽帮助中心'
  }
  next()
});

export default router
